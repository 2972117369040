import React from 'react';
import '../App.css';
import { Box, Grid } from '@mui/material';
import CardLine from '../components/CardLine';
import CardHeader from '../components/CardHeader';
import CardResult from '../components/CardResult';



const MaterialTab = (props) => {

    return (
      <Box >
        <Grid container>
          <Grid item xs={12}>
            <CardLine name='Unit cost'label='cost in $' id='material2' calculate={props.calculate} unit='$/kg' startValue='30' tooltip='How much does your material cost? Take one specific material'/>
          </Grid>
          <Grid item xs={12}>
            <CardLine name='material density'label='in $' id='material3' calculate={props.calculate} unit='g/cm^3' startValue='1.25' tooltip='Use the material density of your specific material'/>
          </Grid>
          <Grid item xs={12}>
            <CardResult name='total material cost'label='' result={props.result} unit='$/g' />
          </Grid>
        </Grid>
      </Box>  
    );
}
 
export default MaterialTab ;
import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Box, Grid, Paper } from '@mui/material';

//custom import
import GeneralTab from './tabs/GeneralTab';
import MaterialTab from './tabs/MaterialTab';
import OverheadTab from './tabs/OverheadTab';
import MachineTab from './tabs/MachineTab';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="box-content"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Interface() {

  const [value, setValue] = React.useState(0);
  //general tab
  const [generalArray, setGeneralArray] = useState([]);
  const [amountMachines, setAmountMachines] = useState();
  const [operatingHoursMachine, setOperatingHoursMachine] = useState();
  const [operatingHoursStaff, setOperatingHoursStaff] = useState();
  const [avgBuildingSpeed, setAvgBuildingSpeed] = useState(1);
  //material tab
  const [materialArray, setMaterialArray] = useState([]);
  const [purchaseUnit, setPurchaseUnit] = useState();
  const [unitCost, setUnitCost] = useState();
  const [materialDensity, setMaterialDensity] = useState();
  //machine tab
  const [machineArray, setMachineArray] = useState([]);
  const [machineCost, setMachineCost] = useState();
  const [machineLifetime, setMachineLifetime] = useState();
  const [machineDepreciation, setMachineDepreciation] = useState();
  const [machineOperatingCost, setMachineOperatingCost] = useState();
  //overhead tab
  const [overheadArray, setOverheadArray] = useState([]);
  const [rent, setRent] = useState();
  const [staffCost, setStaffCost] = useState();
  const [licenseCost, setLicenseCost] = useState();
  const [otherCost, setOtherCost] = useState();
  //results
  const [resultRate, setResultRate] = useState(0);
  const [resultMaterial, setResultMaterial] = useState();
  const [resultMachine, setResultMachine] = useState();
  const [resultOverhead, setResultOverhead] = useState();

  useEffect(() => {
    calculateRate()
  });
  
  
  const calculateRate = () => {
    setAmountMachines(parseInt(localStorage.getItem('general1')));
    setOperatingHoursMachine(parseInt(localStorage.getItem('general2')));
    setOperatingHoursStaff(parseInt(localStorage.getItem('general3')));
    setAvgBuildingSpeed(parseInt(localStorage.getItem('general4')));
    setPurchaseUnit(parseInt(localStorage.getItem('material1')));
    setUnitCost(parseInt(localStorage.getItem('material2')));
    setMaterialDensity(parseInt(localStorage.getItem('material3')));
    setMachineCost(parseInt(localStorage.getItem('machine1')));
    setMachineLifetime(parseInt(localStorage.getItem('machine2')));
    setMachineDepreciation(parseInt(localStorage.getItem('machine3')));
    setMachineOperatingCost(parseInt(localStorage.getItem('machine4')));
    setRent(parseInt(localStorage.getItem('overhead1')));
    setStaffCost(parseInt(localStorage.getItem('overhead2')));
    setLicenseCost(parseInt(localStorage.getItem('overhead3')));
    setOtherCost(parseInt(localStorage.getItem('overhead4')));

    setMachineDepreciation(+machineCost/(+amountMachines)/(+machineLifetime));
    setResultMaterial(unitCost/1000);
    setResultMachine(+machineDepreciation+(+machineOperatingCost/(+amountMachines*+operatingHoursMachine)));
    setResultOverhead((rent+staffCost+licenseCost+otherCost)/(12*amountMachines*operatingHoursStaff));
    setResultRate(resultMaterial+((resultMachine+resultOverhead)/avgBuildingSpeed));

    console.log('calculation has been done');
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log('change')
  };

  const giveResult = (result) => {
    if (isNaN(result)){
      return 'Please take a look at all tabs'
    }
    else {
      return ('The suggested rate is '+result+'$/g')
    }
  }

  return (
    <Box sx={{ width: '40vw', mx: 'auto'}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 'auto' }}>
        <Tabs value={value} 
              onChange={handleChange}
              aria-label="basic tabs example"
        >
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="Material" {...a11yProps(1)} />
          <Tab label="Machine" {...a11yProps(2)} />
          <Tab label="Overhead and Labor" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <GeneralTab
          giveArray={generalArray}
          calculate={calculateRate}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MaterialTab 
          giveArray={materialArray}
          calculate={calculateRate}
          result={resultMaterial}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MachineTab 
          giveArray={machineArray}
          calculate={calculateRate}
          resultDepreciation={machineDepreciation}
          result={resultMachine}
        />
      </TabPanel>
      <TabPanel value={value} index={3} >
        <OverheadTab
          giveArray={overheadArray}
          calculate={calculateRate}
          result={resultOverhead}
        />
      </TabPanel>
      {/* Need to have react component to show Result */}
      <Grid item xs={6} sx={{mx: 'auto', my: '30px'}}>
        <Paper
          elevation={2} 
          sx={{
            textAlign: 'center',
            paddingTop: '5px' ,
            paddingBottom: '5px',
            backgroundColor: '#bbdefb',
            fontSize: '120%'
            }}
        >         
          {giveResult(parseFloat(resultRate,2).toFixed(2))}
          </Paper>
      </Grid>
    </Box>
  );
}